import { CoinSelect } from "./CoinSelect";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

export const TransferCoinSelectionBlock = forwardRef(
  (
    { handleCoinChange, selectedFrom, selectedTo, amount, changeAmountSumm },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <div className="coin-selection-wrapper">
        <p className="coin-selection-label">{t("Выберите монету")}</p>

        <div className="coin-selection-block">
          <CoinSelect
            handleCoinChange={handleCoinChange}
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
          />
          <input
            ref={ref}
            type="number"
            placeholder={t("введите сумму")}
            value={amount}
            onChange={changeAmountSumm}
          />
        </div>
      </div>
    );
  }
);
TransferCoinSelectionBlock.displayName = "TransferCoinSelectionBlock";
