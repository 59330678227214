import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function G1(props) {
  const { t } = useTranslation();
  const data = {
    labels: null,
    datasets: [
      {
        label: t("Сумма"),
        data: [props.prop.btc, props.prop.eth, props.prop.usdt],
        // data: [2.8054, 2.8054, 1.0921],
        backgroundColor: ["#ff0000", "#ff7508", "#47b64c"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
        cutout: "80%",
        // rotation: 160,
        // radius: 90,
        hoverOffset: 4,
      },
    ],
  };

  const config = {
    type: "doughnut",
    data: { data },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };
  return <Doughnut options={config} data={data} />;
}
