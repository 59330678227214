import { useTranslation } from "react-i18next";
const staticDirectory = process.env.REACT_APP_STATIC_DIR_URL
const FooterLinks = () => {

  const { t, i18n } = useTranslation();
  const footerLinks = [
    {
      name: "FAQ",
      link: i18n.resolvedLanguage === 'en'
        ? `${staticDirectory}faq.pdf`
        : `${staticDirectory}faq-${i18n.resolvedLanguage}.pdf`,
    },
    { name: "Новости", link: "https://t.me/cryptouchai" },
    {
      name: "Презентация",
      link: i18n.resolvedLanguage === 'en'
        ? `${staticDirectory}presentation.pdf`
        : `${staticDirectory}presentation-${i18n.resolvedLanguage}.pdf`,
    },
    {
      name: "Документация",
      link: i18n.resolvedLanguage === 'en'
        ? `${staticDirectory}public.pdf`
        : `${staticDirectory}public-${i18n.resolvedLanguage}.pdf`,
    },
    {
      name: "Регистрация",
      link: `${staticDirectory}register.pdf`,
    },
  ];
  return (
    <ul className="main-header-links-list">
      {footerLinks.map(({ name, link }, idx) => (
        <li key={`${idx}-footer-link`}>
          <a href={link}>{t(name)}</a>
        </li>
      ))}
    </ul>
  );
};

export {
    FooterLinks
}