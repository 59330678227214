import { ProgressBar } from "./ProgressBar";
import { RangeList } from "./RangeList";
export const ProgressBarBlock = ({
  percentage,
  handlePercentageChange,
  sliderFillPercentage,
  amount,
  selectedCoin,
  isFetching,
  showCurrentCoin,
}) => {
  return (
    <div className="progress-bar-wrapper">
      <RangeList />
      <ProgressBar
        percentage={percentage}
        handlePercentageChange={handlePercentageChange}
        sliderFillPercentage={sliderFillPercentage}
      />
      <div className="coin-amount-wrapper">
        <p>{amount + " " + selectedCoin.value.toUpperCase()}</p>
        <p>{!isFetching && showCurrentCoin()}</p>
      </div>
    </div>
  );
};
