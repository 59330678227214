import { ReactComponent as FlowerIcon } from "../assets/icons/FlowerIcon.svg";
import { ReactComponent as AttentionIcon } from "../assets/icons/AttentionIcon.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dropdown = () => {
  const {t, i18n} = useTranslation()

  return (
    <div className="aside-drop-down-container">
      <div className="aside-dropdown-inner-wrapper">
        <span>
          <FlowerIcon />
        </span>
        <span>{t("AI")}</span>
      </div>
      <nav className="main-nav__wrapper">
        <ul className="aside-drop-down-list">
          <li>
            <NavLink className="link" to="balance">
              {t("Главный баланс")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="algoritms">
              {t("Алгоритмы")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="faq">
              {t("Настройки")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="statistics">
              {t("Статистика")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="referals">
              {t("Рефералы")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="ai_trading">
              {t("AI Торговля")}
            </NavLink>
          </li>
          <li className="main-nav__first-lvl-item">
            <a
              className="link"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://cryptouch.ai/staticDirectory/pages/faq.pdf"
                  : "https://cryptouch.ai/staticDirectory/pages/faq-ru.pdf"
              }
            >
              <span>
                <AttentionIcon />
              </span>
              <span>{t("Часто задаваемые вопросы")}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Dropdown };
