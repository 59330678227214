import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { transformDataForWeeklyChart } from "../../helpers/transformDataForWeeklyChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const WeeklyPercentage = ({ type, otherProps }) => {


  const datasets = transformDataForWeeklyChart(type, otherProps);
  const data = {
    labels: otherProps?.labels,
    datasets,
  };

  const config = {
    type: "line",
    data: data,
    options: {
      
      scales: {
        y: {
          display: true,
          grid: {
            color: "rgba(255, 255, 255, 0.5)",
          },
          ticks: {
            color: "white",
            callback: function (value, index, values) {
              if (index === 0 || index === values.length - 1) {
                return Math.round((value + Number.EPSILON) * 100) / 100 + "%";
              } else {
                return "";
              }
            },
          },
        },
        x: {
          grid: {
            color: "rgba(255, 255, 255, 0.5)",
          },
          ticks: {
            color: "white",
            beginAtZero: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y",
              value: 0,
              borderColor: "#FFF831",
              borderRadius: "50%",
              borderWidth: 1,
            },
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y",
              value: 20,
              borderColor: "#FFF831",
              borderWidth: 1,
              borderRadius: "50%",
            },
          ],
        },
      },
    },
  };

  return <Line options={config} {...config} data={data} />;
};
