import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const binanceApi = createApi({
  reducerPath: "binanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.binance.com/api/v3",
  }),
  endpoints: (builder) => ({
    getKlinesHistory: builder.query({
      query: ({ symbol, interval, limit }) => ({
        url: `klines`,
        params: {
          symbol,
          interval,
          limit
        },
      }),
    }),
  }),
});

export const { useGetKlinesHistoryQuery } = binanceApi;
