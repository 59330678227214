import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../toolkitReducers/auth.slice";
import { setBetHistory, setBinary } from "../../toolkitReducers/actions.slice";
import { TimeControls } from "./TimeControls";
import { InvestmentControls } from "./InvestmentControls";
import { BettingControls } from "./BettingControls";
import { selectBetHistory } from "../../toolkitReducers/selectors";
import { PairSwitcher } from "./PairSwitcher";
import { OptionsBalanceBlock } from "./OptionsBalanceBlock";
import { PercentageBlock } from "./PercentageBlock";
import { VictoryCryptoChart } from "./VictoryCryptoChart";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";

export const BinaryOptionSection = ({
  selectedPair,
  onPairChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [payout, setPayout] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [formTime, setFormTime] = useState(null);
  const [upDownValue, setUpDownValue] = useState(null);
  const [isErrors, setErrors] = useState([]);

  const betHistory = useSelector(selectBetHistory);
  const [trades, setTrades] = useState([]);
  const { data: userData } = useGetUserDataQuery();

  const removeError = (errorToRemove) => {
    setErrors((prevErrors) =>
      [...prevErrors].filter((error) => error !== errorToRemove)
    );
  };

  const profitFormula = () => {
    let potentialPayout =
      investment * (userData?.user_level_percentage / 100) + investment;

    setPayout(potentialPayout);
  };
  const getUpAndDown = (typeButton) => {
    setUpDownValue(typeButton);
    profitFormula();

    setTimeout(() => {
      setUpDownValue(null);
    }, 500);
  };
  const handleError = (existingErrors, newError) => {
    if (existingErrors.includes(newError)) {
      return existingErrors;
    }
    return [...existingErrors, newError];
  };
  const startBinary = (buttonType) => {
    if (investment === 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна равняться 0")
      );
      return;
    }
    if (investment > userData?.options_crypto_balance_usdt) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна быть более баланса")
      );
      return;
    }
    if (formTime <= 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "время не может равняться нулю")
      );
      return;
    }
    let obj = {
      selectedPair: selectedPair,
      investment: investment,
      formTime: formTime,
      upDownValue: buttonType,
    };
    dispatch(setBinary(obj));
    getUpAndDown(buttonType);
    dispatch(setBetHistory());
    setTimeout(() => dispatch(setBetHistory()), 500);
    setTimeout(() => dispatch(setUserData()), 1000);
  };

  const getInvestment = (investment) => {
    setInvestment(investment);
    profitFormula();
  };
  const getFormTime = (time) => {
    setFormTime(time);
    profitFormula();
  };
  // Function to filter betHistory based on selectedPair
  const filterBetHistoryByPair = (history, pair) => {
    return history.filter((item) => item.currency === pair && !item.resolved);
  };
  useEffect(() => {
    const filteredTrades =
      betHistory &&
      betHistory.length &&
      filterBetHistoryByPair(betHistory, selectedPair)
        .map(({ id, date, expiration_time, target_price }) => ({
          date,
          expiration_time,
          id,
          target_price,
        }))
        .slice(-5);
    setTrades(filteredTrades);
  }, [betHistory, selectedPair]);

  return (
    <section className="binary-option-section">
      <h1>{t("AI Торговля")}</h1>
      <div className="binary-options-block">
        <div className="left-side">
          <div className="binary-auction-wrapper">
            <VictoryCryptoChart
              interval="1m"
              symbol={selectedPair.replace("/", "")}
              trades={trades}

            />
          </div>
        </div>
        <div className="binary-right-side">
          <PairSwitcher
            selectedPair={selectedPair}
            setSelectedPair={onPairChange}
          />
          <button disabled className="binary-transfer-button">
            {t("Трансфер")}
          </button>
          <OptionsBalanceBlock betHistory={betHistory} />
          <PercentageBlock selectedPair={selectedPair} />
          <div className="controls-block">
            <div className="time-investment-controls-wrapper">
              <TimeControls
                getFormTime={getFormTime}
                setErrors={setErrors}
                handleError={handleError}
                removeError={removeError}
              />
              <InvestmentControls
                getInvestment={getInvestment}
                investment={investment}
                setInvestment={setInvestment}
                profitFormula={profitFormula}
                setErrors={setErrors}
                handleError={handleError}
                removeError={removeError}
                freeBalance={userData?.options_crypto_balance_usdt}
              />
            </div>
            <BettingControls
              selectedPair={selectedPair}
              getBalance={userData?.options_crypto_balance_usdt}
              payout={payout}
              startBinary={startBinary}
              activeButton={upDownValue}
            />

            {isErrors &&
              isErrors.map((item, index) => (
                <p key={index + "error"}>{t(item)}</p>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
