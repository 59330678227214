import { BinaryOptionSection } from "./BinaryOptionsSection";
import { PendingBetsTable } from "../../Elements/Tables/PendingBetsTable";
import { useEffect, useState } from "react";
import { BidsTable } from "./BidsTable";
import { symbols } from "../../helpers/symbols";
import { websocketEmitter } from "../../helpers/EventEmitter";
const BinaryOptionPage = () => {

  const [selectedPair, setSelectedPair] = useState(Object.keys(symbols)[0]);

  const onPairChange = (pair) => {
    setSelectedPair(pair)
  } 
  const symbol=selectedPair.replace("/", "")
  const interval = '1m'
  useEffect(() => {
    const websocketInstance = new WebSocket(
      `wss://cryptouch.ai/ws/crypto-prices/${symbol.toLowerCase()}@kline_${interval}`
    );
    websocketInstance.onopen = () => {
      console.log("Websocket connection opened");
    };
    websocketInstance.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      
      if (messageData.websocket_data && messageData.websocket_data.e === "kline") {
        websocketEmitter.emit('onKlinesDataMessage', messageData.websocket_data);
      }
  
      if (messageData.bids && messageData.asks) {
        websocketEmitter.emit('onBidsTableDataMessage', {
          bids: messageData.bids,
          asks: messageData.asks,
        });
        websocketEmitter.emit('onBidsForChartMessage', {
          sellPrice: messageData.bids[0][0],
          buyPrice: messageData.asks[0][0],
          timestamp: new Date()
        });
      }
    };
  websocketInstance.oclose = () => {
    console.log("Websocket connection closed");
  };
    return () => {
      websocketInstance.close();
    };
  }, [symbol]);
  return (
    <>
      <BinaryOptionSection onPairChange={onPairChange} selectedPair={selectedPair} />
      <section className="binary-option-section">
        <BidsTable symbol={symbol} />
      </section>
      {/* <section className="binary-option-section">
        <PendingBetsTable />
      </section> */}
    </>
  );
};

export default BinaryOptionPage;