import { useSelector } from "react-redux";
import { selectLanguage } from "../../toolkitReducers/selectors";
import { useTranslation } from "react-i18next";

const LowerPartLinks = () => {
        const language = useSelector(selectLanguage);
    const { t } = useTranslation();


    let english = language === "en";
    const lowerPartLinks = [
  {
    text: "Условия использования",
    link: english
      ? "https://cryptouch.ai/staticDirectory/pages/reglament.pdf"
      : "https://cryptouch.ai/staticDirectory/pages/reglament-ru.pdf",
  },
  {
    text: "Политика Конфиденциальности",
    link: english
      ? "https://cryptouch.ai/staticDirectory/pages/agreement.pdf"
      : "https://cryptouch.ai/staticDirectory/pages/agreement-ru.pdf",
  },
  {
    text: "Уведомление о рисках",
    link: english
      ? "https://cryptouch.ai/staticDirectory/pages/risks.pdf"
      : "https://cryptouch.ai/staticDirectory/pages/risks-ru.pdf",
  },
  {
    text: "Политика использования cookie",
    link: english
      ? "https://cryptouch.ai/staticDirectory/pages/coockie.pdf"
      : "https://cryptouch.ai/staticDirectory/pages/coockie-ru.pdf",
  },
  {
    text: "KYC",
    link: english
      ? "https://cryptouch.ai/staticDirectory/pages/kyc.pdf"
      : "https://cryptouch.ai/staticDirectory/pages/kyc-ru.pdf",
  },
];
    return (
         <ul className="footer-secondary-links">
        {lowerPartLinks.map(({text, link}, idx) => 
          <li key={3+idx+text}>
            <a href={link}>
            {t(text)}
            </a>
          </li>
        ) }
        </ul>
    )
}

export { LowerPartLinks };