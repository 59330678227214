// Use this function to create date string for Header component
const createDateString = () => {
  const date = new Date();
  const x = date.toLocaleDateString("uk-UA"); // Using Ukrainian locale

  const [d, m, y] = x.split("."); // Reversed day and month due to Ukrainian locale format
  return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
};

export { createDateString };

export function transformDateTimeForSessionsTable(dateTimeString) {
  if (!dateTimeString) return "-";
  const dateTime = new Date(dateTimeString);

  // Extract date components
  const year = dateTime.getFullYear().toString().slice(-2);
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getDate().toString().padStart(2, "0");

  // Extract time components
  // const hours = dateTime.getHours().toString().padStart(2, "0");
  // const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  // const seconds = dateTime.getSeconds().toString().padStart(2, "0");

  // Format the date and time
  // const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  const formattedDateTime = `${day}.${month}.${year}`;
  return formattedDateTime;
}
