import { useState, useEffect, useMemo, useCallback } from "react";
import {
  VictoryChart,
  VictoryCandlestick,
  VictoryAxis,
  VictoryTheme,
  VictoryZoomContainer,
  VictoryLabel,
  VictoryScatter,
  VictoryLine,
} from "victory";
import { formatTimestamp } from "../../helpers/formatTimestamp";
import { useGetKlinesHistoryQuery } from "../../toolkitReducers/binanceApi";
import { BinaryChartLoader } from "./BinaryChartLoader";
import { getTimeDifference } from "../../helpers/getTimeDifference";
import { websocketEmitter } from "../../helpers/EventEmitter";
export const VictoryCryptoChart = ({ symbol, interval, trades }) => {
  const [data, setData] = useState([]);
  const [zoomDomain, setZoomDomain] = useState();
  const [currentPrice, setCurrentPrice] = useState(null);
  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);

  const {
    data: klinesData,
    isFetching,
    refetch,
  } = useGetKlinesHistoryQuery({
    symbol,
    interval,
    limit: 50,
  });

  useEffect(() => {
    refetch();
  }, [refetch, symbol, interval]);

  const initialData = useMemo(() => {
    if (!klinesData) return [];
    return klinesData.map((kline) => ({
      x: kline[0], // Kline start time as timestamp
      open: parseFloat(kline[1]), // Open price
      close: parseFloat(kline[4]), // Close price
      high: parseFloat(kline[2]), // High price
      low: parseFloat(kline[3]), // Low price
    }));
  }, [klinesData]);

  useEffect(() => {
    if (initialData.length) {
      setData(initialData);
      setCurrentPrice(initialData[initialData.length - 1].close);
      const lastIndex = initialData.length - 1;
      const halfVisibleCandles = Math.ceil(initialData.length / 2);
      const centerIndex = Math.max(0, lastIndex - halfVisibleCandles);
      const minLow = Math.min(...initialData.map((d) => d.low));
      const maxHigh = Math.max(...initialData.map((d) => d.high));

      setZoomDomain({
        x: [
          initialData[centerIndex].x,
          initialData[lastIndex].x +
            (initialData[lastIndex].x - initialData[centerIndex].x),
        ],
        y: [minLow - minLow * 0.0025, maxHigh + maxHigh * 0.0025], // 5% padding above and below
      });
    }
  }, [initialData]);

  // Listen to WebSocket messages via websocketEmitter
  useEffect(() => {
    const handleKlineUpdate = (kline) => {
      // setData([]);
      // setCurrentPrice(null);
      // setZoomDomain(null);
      const newPoint = {
        x: kline.k.t,
        open: parseFloat(kline.k.o),
        close: parseFloat(kline.k.c),
        high: parseFloat(kline.k.h),
        low: parseFloat(kline.k.l),
      };

      setData((prevData) => {
        const index = prevData.findIndex((d) => d.x === newPoint.x);

        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = newPoint;
          return updatedData;
        } else {
          const newData = [...prevData, newPoint];
          if (newData.length > 50) {
            newData.shift(); // Remove the first element
          }
          const lastIndex = newData.length - 1;
          const halfVisibleCandles = Math.ceil(newData.length / 2);
          const centerIndex = Math.max(0, lastIndex - halfVisibleCandles);
          const minLow = Math.min(...newData.map((d) => d.low));
          const maxHigh = Math.max(...newData.map((d) => d.high));

          setZoomDomain({
            x: [
              newData[centerIndex].x,
              newData[lastIndex].x +
                (newData[lastIndex].x - newData[centerIndex].x),
            ],
            y: [minLow - minLow * 0.0025, maxHigh + maxHigh * 0.0025], // 5% padding above and below
          });
          return newData;
        }
      });

      setCurrentPrice(newPoint.close);
    };
    const handleBidsForChartUpdate = (messageData) => {
      const { sellPrice, buyPrice, timestamp } = messageData;

      // Randomly decide whether to push to bids or asks
      if (Math.random() < 0.5) {
        // Check if the last bid is different from the new sellPrice
        setBids((prevBids) => {
          const lastBid = prevBids[prevBids.length - 1]; // Get the last bid if it exists
          if (!lastBid || lastBid.price !== sellPrice) {
            return [...prevBids, { price: sellPrice, timestamp }];
          }
          return prevBids; // Return previous state if no update is needed
        });
      } else {
        // Check if the last ask is different from the new buyPrice
        setAsks((prevAsks) => {
          const lastAsk = prevAsks[prevAsks.length - 1]; // Get the last ask if it exists
          if (!lastAsk || lastAsk.price !== buyPrice) {
            return [...prevAsks, { price: buyPrice, timestamp }];
          }
          return prevAsks; // Return previous state if no update is needed
        });
      }
    };

    // Subscribe to 'onKlinesDataMessage' event
    websocketEmitter.subscribe("onKlinesDataMessage", handleKlineUpdate);
    websocketEmitter.subscribe(
      "onBidsForChartMessage",
      handleBidsForChartUpdate
    );

    return () => {
      // Unsubscribe from event when the component is unmounted or dependencies change
      websocketEmitter.unsubscribe("onKlinesDataMessage", handleKlineUpdate);
      websocketEmitter.unsubscribe(
        "onBidsForChartMessage",
        handleBidsForChartUpdate
      );
    };
  }, [symbol, interval, asks]);

  const calculateCandleWidth = useCallback(() => {
    if (!zoomDomain || !data.length) return 80; // default width
    const visibleDataPoints = data.filter(
      (d) => d.x >= zoomDomain.x[0] && d.x <= zoomDomain.x[1]
    ).length;
    // if (!visibleDataPoints) {
    //   return 80;
    // }
    return Math.max(2, 250 / visibleDataPoints); // min width is 2, adjust max width proportionally
  }, [zoomDomain, data]);

  const candleWidth = calculateCandleWidth();

  const handleZoomDomainChange = useCallback(
    (domain) => {
      const lastIndex = data.length - 1;
      const lastCandle = data[lastIndex];
      // Use maxZoomX from state
      const maxZoomX = lastCandle
        ? lastCandle.x + (lastCandle.x - data[0].x) / 2
        : maxZoomX;

      // Calculate the new domain with the maxZoomX restriction
      let newDomain = {
        ...domain,
        x: [Math.max(domain.x[0], data[0].x), Math.min(domain.x[1], maxZoomX)],
      };

      const visibleData = data.filter(
        (d) => d.x >= newDomain.x[0] && d.x <= newDomain.x[1]
      );

      if (visibleData.length === 0) {
        setZoomDomain(newDomain);
        return;
      }

      const minLow = Math.min(...visibleData.map((d) => d.low));
      const maxHigh = Math.max(...visibleData.map((d) => d.high));
      const timeDifference = newDomain.x[1] - newDomain.x[0];
      const minTimeDifference = getTimeDifference(interval);

      // Adjust domain if the time difference is less than minTimeDifference
      if (timeDifference < minTimeDifference) {
        const midPoint = (newDomain.x[0] + newDomain.x[1]) / 2;
        newDomain.x = [
          midPoint - minTimeDifference / 2,
          midPoint + minTimeDifference / 2,
        ];
      }

      // Set the new zoom domain
      setZoomDomain({
        x: newDomain.x,
        y: [minLow - minLow * 0.0025, maxHigh + maxHigh * 0.0025], // Small padding above and below
      });
    },
    [data, interval]
  );

  return (
    <>
      {isFetching && !data.length && <BinaryChartLoader extended />}
      {!isFetching && data.length && (
        <VictoryChart
          width={700}
          height={700}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              zoomDomain={zoomDomain}
              onZoomDomainChange={handleZoomDomainChange}
              // allowPan={false}
              minimumZoom={{ x: getTimeDifference(interval), y: 1.5 }}
            />
          }
          scale={{ x: "time" }}
          domainPadding={{ x: 10 }}
          padding={{ left: 65, right: 0, top: 20, bottom: 30 }}
        >
          <VictoryAxis
            style={{
              axis: { stroke: "transparent" },
              axisLabel: { padding: 40 },
              grid: {
                stroke: "rgba(228, 228, 222, 0.30)",
                strokeWidth: 1,
                strokeDasharray: "none",
              },
              tickLabels: { fontSize: 12, fill: "#67E6A8" },
            }}
            tickFormat={(x) => formatTimestamp(x, interval)}
          />
          <VictoryAxis
            dependentAxis
            // tickCount={12}
            style={{
              axis: { stroke: "transparent" },
              axisLabel: { padding: 40 },
              grid: {
                stroke: "rgba(228, 228, 222, 0.30)",
                strokeWidth: 1,
                strokeDasharray: "none",
              },
              tickLabels: { fontSize: 12, fill: "#67E6A8" },
            }}
          />
          <VictoryCandlestick
            style={{
              data: {
                stroke: (d) => (d.close > d.open ? "#eb5757" : "#67e6a8"),
                width: candleWidth,
                parent: { border: "none" },
              },
            }}
            animate={{
              duration: 500,
              onLoad: { duration: 500 },
            }}
            wickStrokeWidth={3}
            candleWidth={candleWidth}
            candleColors={{ positive: "#67e6a8", negative: "#eb5757" }}
            candleRatio={0.8}
            data={data}
            close="close"
            high="high"
            low="low"
            open="open"
          />
          {currentPrice && !isNaN(currentPrice) && (
            <VictoryLine
              style={{
                data: {
                  stroke: "#67e6a8",
                  strokeWidth: 1,
                  strokeDasharray: "4,4",
                },
              }}
              animate={{
                duration: 250,
                easing: "linear",
              }}
              data={[
                {
                  x: data?.length && data[0]?.x,
                  y: currentPrice && currentPrice,
                },
                {
                  x: zoomDomain?.x[1],
                  y: currentPrice && currentPrice,
                },
              ]}
            />
          )}
          {asks?.length > 0 &&
            asks.map((ask, idx) => {
              const xValue = new Date(ask.timestamp).getTime(); // Convert timestamp to milliseconds
              const yValue = Number(ask.price); // Ensure price is a number

              // Validate values
              if (!isNaN(xValue) && !isNaN(yValue)) {
                return (
                  <VictoryScatter
                    key={`ask-point-${ask.timestamp}-${idx}`} // Unique key
                    data={[{ x: xValue, y: yValue }]} // Set x and y based on timestamp and price
                    style={{
                      data: {
                        fill: "#67e6a8",
                        stroke: "#67e6a8",
                        strokeWidth: 1,
                      }, // Green color for the point
                    }}
                    size={2} // Size of the point
                    // labels={() => [`Price: ${yValue}`, `Time: ${new Date(xValue).toLocaleTimeString()}`]} // Tooltip text
                    labelComponent={
                      <VictoryLabel dy={-10} style={{ fill: "#67e6a8" }} />
                    } // Label for points
                  />
                );
              } else {
                console.warn(`Invalid data for ask:`, ask); // Log if the data is invalid
                return null; // Return null for invalid data
              }
            })}
          {bids?.length > 0 &&
            bids.map((bid, idx) => {
              const xValue = new Date(bid.timestamp).getTime(); // Convert timestamp to milliseconds
              const yValue = Number(bid.price); // Ensure price is a number

              // Validate values
              if (!isNaN(xValue) && !isNaN(yValue)) {
                return (
                  <VictoryScatter
                    key={`bid-point-${bid.timestamp}-${idx}`} // Unique key
                    data={[{ x: xValue, y: yValue }]} // Set x and y based on timestamp and price
                    style={{
                      data: { fill: "red", stroke: "red", strokeWidth: 1 }, // Red color for bid points
                    }}
                    size={2} // Size of the point
                    labelComponent={
                      <VictoryLabel dy={-10} style={{ fill: "red" }} />
                    } // Label for points
                  />
                );
              } else {
                console.warn(`Invalid data for bid:`, bid); // Log if the data is invalid
                return null; // Return null for invalid data
              }
            })}
        </VictoryChart>
      )}
    </>
  );
};
