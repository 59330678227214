import { useEffect} from "react";
import ReactDOM from "react-dom";

export const Popup = ({ props, children }) => {
  const { onModalClose, expanded } = props;

  useEffect(() => {
    if (expanded) {
      window.addEventListener("keydown", onModalClose);
      document.body.style.overflow = "hidden";
    }

    return () => {
      window.removeEventListener("keydown", onModalClose);
      document.body.style.overflow = "auto";
    };
  }, [onModalClose, expanded]);


  return ReactDOM.createPortal(
    <div
      className={expanded ? "overlay is-visible" : "overlay"}
      id="overlay"
      onClick={onModalClose}
    >
      <div className="modal-window-advantages">{children}</div>
    </div>,
    document.getElementById("modal-root")
  );
};
