import { combineReducers } from "@reduxjs/toolkit";
import themeSlice from "./themeSlice";
import authSlice from "./auth.slice";
import stateSlice from "./actions.slice";
import { cryptouchAPI } from "./cryptouch";
import { ipDataApi } from "./ipDataApi";
import { binanceApi } from "./binanceApi";
export * from "./auth.slice";
export * from "./actions.slice";


const rootReducer = combineReducers({
  auth: authSlice,
  state: stateSlice,
  theme: themeSlice,
  [cryptouchAPI.reducerPath]: cryptouchAPI.reducer,
  [ipDataApi.reducerPath]: ipDataApi.reducer,
  [binanceApi.reducerPath]: binanceApi.reducer
},
);

export default rootReducer;
