import { ReactComponent as LogoIcon } from "../../assets/images/logo.svg";
import logoPNg from "../../assets/icons/logo-large.svg";
export function AuthLogo(params) {
  return (
    <div className="login-page__logo-wrapper rel">
      <img src={logoPNg} alt="" />
      {/* <LogoIcon /> */}
    </div>
  );
}
