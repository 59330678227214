import { useTranslation } from "react-i18next";

export const NextButton = ({ onClick, text = "Далее", disabled = false }) => {
  const { t } = useTranslation();

  return (
    <button className="next-btn" onClick={onClick} disabled={disabled}>
      {t(text)}
    </button>
  );
};
