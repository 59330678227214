import { useTranslation } from "react-i18next";
export const WarningTransferDescription = ({ localError }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="warning-description">
        {"* " +
          t(
            "Платформа не снимает комиссию за вывод средств. Комиссия сети блокчейн будет взята из суммы транзакции."
          )}
      </p>
      <>
        {localError && (
          <p
            style={{ fontSize: "20px", lineHeight: "1.5" }}
            className="withdrawal-section__add-deposit-description"
          >
            {t(localError)}
          </p>
        )}
      </>
    </>
  );
};
