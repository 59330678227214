
import { useTranslation } from "react-i18next";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";

const UserInfo = ({ balance }) => {

const {data} = useGetUserDataQuery()

  const { t } = useTranslation();
  return (
    <div className="referals-section-info-wrapper">
      <div className="info-wrapper-upper-part">
        <h2>{`${data && data.username} ${data && data.last_name}`}</h2>
        <div className="gradient-wrapper">
          <h3>{t("Бонусный баланс")}:</h3>
        </div>
      </div>
      <ul>
        <li>
          <p>{t("Баланс")} BTC</p>
          <span>{parseFloat(balance?.btc) + " BTC"}</span>
        </li>
        <li>
          <p>{t("Баланс")} ETH</p>
          <span>{parseFloat(balance?.eth) + " ETH"}</span>
        </li>
        <li>
          <p>{t("Баланс")} USDT</p>
          <span>{parseFloat(balance?.usdt).toFixed(2) + " USDT"}</span>
        </li>
      </ul>
    </div>
  );
};

export { UserInfo };
