import Birthday from "../../assets/images/events-section/cryptouch-birthday.png";
// import Moscow from "../../assets/images/events-section/cryptouch-moscow.png";
import Kazakhstan from '../../assets/images/events-section/kazakhstan.png'
import Spain from "../../assets/images/events-section/spain.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as VideoPlay } from "../../assets/icons/promo-section/video-play.svg";
export const Events = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="main-page-section events-section">
      <div className="section-inner-container">
        <h3>{t("CrypTouch события")}</h3>
        <div className="images-row">
          <div className="thumb">
            <img src={Birthday} alt={t("День рождения Cryptouch")} />
            <a
              rel="noreferrer"
              target="_blank"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://www.youtube.com/watch?v=h_TFYDUV9sM"
                  : "https://www.youtube.com/watch?v=h_TFYDUV9sM"
              }
            >
              <VideoPlay />
            </a>
          </div>
          <div className="thumb">
            <img src={Kazakhstan} alt={t("Казахстан")} />
            <a
              rel="noreferrer"
              target="_blank"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://youtu.be/XHK-UceeKGM"
                  : "https://youtu.be/XHK-UceeKGM"
              }
            >
              <VideoPlay />
            </a>
          </div>
          <div className="thumb">
            <img src={Spain} alt={t("Испания")} />
            <a
              rel="noreferrer"
              target="_blank"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://youtu.be/GM_KR3RASHE"
                  : "https://youtu.be/GM_KR3RASHE"
              }
            >
              <VideoPlay />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
