import { ReactComponent as SmallLogo } from "../../assets/icons/logo-header-main.svg";
import { LanguageSelect } from "../../Elements/LanguageSelect";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { links } from "./links";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../../toolkitReducers/themeSlice";
const Header = ({ scrollToElement }) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };
  return (
    <header className="temporary-page-header">
      <div className="main-header-info-logo">
        <Link to="/profile">
          <SmallLogo className="img-abs" />
        </Link>
      </div>
      <ul className="main-header-links-list">
        {links.map(({ name, elem }, idx) => (
          <li key={`${idx}-header-link`} onClick={() => scrollToElement(elem)}>
            {t(name)}
          </li>
        ))}
      </ul>
      <div className="page-header-right-side">
        <LanguageSelect />
        <Link className="btn" to="/login">
          {t("Войти")}
        </Link>
        <button onClick={handleToggleTheme} className="switch-mode">
          {theme === "light" ? <MdOutlineDarkMode /> : <MdOutlineLightMode />}
        </button>
      </div>
    </header>
  );
};

export { Header };
