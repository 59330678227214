import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthLogo } from "./AuthLogo";
import { registerNewUser, resetRegister } from "./../../toolkitReducers";
import { ReactComponent as IconEyes } from "../../assets/icons/PassIcon.svg";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { loginUser, getUserData } from "./../../toolkitReducers";
import { getToken } from "./../../helpers";

export default function RegisterPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [isError, setisError] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();

  const refID = searchParams.get("ref_id") || null;


  const [formData, setFormData] = useState({
    username: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    refID,
    acceptTerms: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRegistered = useSelector((state) => state.auth.registered);
  const registerErrors = useSelector((state) => state.auth.registerErrors);
  const isLoggedIn = useSelector((state) => state.auth.isAuth);

  useEffect(() => {
    if (getToken() !== null) {
      dispatch(getUserData());
      isLoggedIn && navigate("/profile");
    }
  }, [dispatch, isLoggedIn, navigate]);

  useEffect(() => {
    if (isRegistered) {
      dispatch(
        loginUser({
          useLogin: formData.email,
          usePassword: formData.password,
        })
      );
    }
  }, [dispatch, formData.email, formData.password, isRegistered]);
  // chrome optimize
  useEffect(() => {
    setIsMounted(true);

    if (isRegistered && isMounted && !registerErrors) {
      dispatch(resetRegister());
    }
    return () => {
      setIsMounted(false);
    };
  }, [isRegistered, isMounted, registerErrors, dispatch, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;
    setisError(false);
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password === formData.confirmPassword) {
      dispatch(registerNewUser(formData));
    } else {
      setisError(true);
    }
  };

  return (
    <div className="register-page page">
      <AuthLogo />

      <section className="register small-wrapper">
        <div className="register__headings-wrapper headings-wrapper auth-headings">
          <h1 className="register__heading h3">{t("Регистрация")}</h1>
          <p className="register__description">
            {t("Заполните пожалуйста информацию ниже")}:
          </p>
        </div>

        <form className="form register__form" onSubmit={handleSubmit}>
          <div className="form-container">
            <label htmlFor="username">*{t("Имя")}</label>
            <input
              required
              type="text"
              placeholder={t("Имя")}
              name="username"
              tabIndex="1"
              id="username"
              value={formData.username}
              onChange={handleChange}
            />
            {registerErrors?.username && (
              <div>{registerErrors?.username[0]}</div>
            )}
          </div>
          <div className="form-container password">
            <label htmlFor="last_name">{t("Фамилия")}</label>
            <div className="password__container">
    
              <input
                required
                type="text"
                placeholder={t("Фамилия")}
                name="last_name"
                tabIndex="2"
                id="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
              {registerErrors?.username && (
                <div>{registerErrors?.username[0]}</div>
              )}
            </div>
          </div>
          <div className="form-container js-form-parent">
            <label htmlFor="email">*{t("Почта")}</label>
            <input
              required
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              tabIndex="2"
              value={formData.email}
              onChange={handleChange}
            />
            {registerErrors?.email && <div>{registerErrors?.email[0]}</div>}
            <span aria-label="valid-email" className="form__error-message">
              {t("Неверный формат")}
            </span>
          </div>
          <div className="form-container js-form-parent">
            <label htmlFor="email">*{t("Телефон")}</label>
            <input
              required
              type="tel"
              placeholder={t("Телефон")}
              name="phone"
              id="phone"
              tabIndex="2"
              value={formData.phone}
              onChange={handleChange}
            />
            {registerErrors?.email && <div>{registerErrors?.email[0]}</div>}
            <span aria-label="valid-email" className="form__error-message">
              {t("Неверный формат")}
            </span>
          </div>
          <div className="form-container password">
            <label htmlFor="password">*{t("Пароль")}</label>

            <div className="password__container">
              <button
                type="button"
                className="password__eye"
                onClick={() => setShowPass(!showPass)}
              >
                <IconEyes />
              </button>

              <input
                required
                type={showPass ? "text" : "password"}
                placeholder={t("Пароль")}
                name="password"
                tabIndex="3"
                id="password"
                value={formData.password}
                onChange={handleChange}
              />
              {isError ? (
                <span
                  aria-label="valid-email"
                  className={`${
                    isError && "active"
                  }  password-error form__error-message`}
                >
                  {t("Passwords must be the same")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="form-container password">
            <label htmlFor="confirm-password">*{t("Повторите пароль")}</label>

            <div className="password__container">
              <button
                type={showConfirmPassword ? "text" : "password"}
                className="password__eye"
                onClick={() => setshowConfirmPassword(!showConfirmPassword)}
              >
                <IconEyes />
              </button>
              <input
                required
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("Подтвердите пароль")}
                name="confirmPassword"
                id="confirm-password"
                tabIndex="4"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <span aria-label="valid-email" className="form__error-message">
                {t("Error message")}
              </span>
            </div>
          </div>
          <div className="form-container--checkbox checkbox">
            <input
              required
              className="form__custom-checkbox"
              type="checkbox"
              name="acceptTerms"
              id="accept"
              checked={formData.acceptTerms}
              onChange={handleChange}
              tabIndex="5"
            />
            <label htmlFor="accept">
              {t("Я согласен с условиями пользования платформой")}
            </label>
          </div>

          <button type="submit" className="js-send-btn btn" tabIndex="7">
            {t("Зарегистрироваться")}
          </button>
        </form>
      </section>
    </div>
  );
}
