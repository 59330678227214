import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

export const ConfigureAI = () => {
      const { t } = useTranslation();
  return (
    <Link to="/faq" className="configure-ai__label">
      {t("Настроить")} AI
    </Link>
  );
};
