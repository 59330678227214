import { ReactComponent as SearchIcon } from "../../assets/icons/refferals-tree-table/search.svg";
import { useTranslation } from "react-i18next";
export const RefferalsFilter = ({ handleFilterChange }) => {
  const { t } = useTranslation();

  return (
    <div  className="refferals-tree-filter">
      <input
        type="text"
        onChange={handleFilterChange}
        placeholder={t("Поиск")}
       
      />
      <SearchIcon />
    </div>
  );
};
