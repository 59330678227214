function betTypeToInitialLang(betType) {
    return betType === "Higher" ? "Выше" : "Ниже";


}
function betStatusToInitialLang(betStatus) {
    let translatedStatus;
    switch (betStatus) {
      case "Win":
        translatedStatus = "Выигрыш";
        break;
      case "Loose":
        translatedStatus = "Проигрыш";
        break;
      case "Neutral":
        translatedStatus = "Нейтрально";
        break;
      case "Pending":
        translatedStatus = "Ожидание";
        break;
      default: console.log('Invalid bet status')
    }
    return translatedStatus;
}


export {
    betTypeToInitialLang,
    betStatusToInitialLang
}