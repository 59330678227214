import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Algoritms = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <section ref={ref} className="main-page-section algoritms-section">
      <div className="section-inner-container">
        <h3>{t("CrypTouch Алгоритмы")}</h3>
        <div className="cards-holder">
          <div className="card-thumb">
            <div className="card-content">
              <div className="left-side">
                <h4>{t("Торговый Алгоритм")}:</h4>
                <p className="subtitle">
                  <span>{t("До")} 7% </span>
                  {t("Доходность в месяц")}
                </p>
                <p className="description">
                  {t(
                    "Инвестируйте в продукт который работает на одной бирже"
                  )}
                </p>
              </div>
              <div className="right-side">
                <p className="heading">{t("Сумма")}:</p>
                <p className="heading">{t("от 50 USDT")}</p>
                <p>{t("Вид торговли: Спотовая")}</p>
              </div>
            </div>
            <Link to="/register" className="main-page-button-link btn">
              {t("Подключить")}
            </Link>
          </div>

          <div className="card-thumb">
            <div className="card-content">
              <div className="left-side">
                <h4>{t("Арбитражный Алгоритм")}:</h4>
                <p className="subtitle">
                  <span>{t("До")} 10% </span>
                  {t("Доходность в месяц")}
                </p>
                <p className="description">
                  {t(
                    "Инвестируйте в продукт который работает на одной бирже"
                  )}
                </p>
              </div>
              <div className="right-side">
                <p className="heading">{t("Сумма")}:</p>
                <p className="heading">{t("от 100 USDT")}</p>
                <p>{t("Вид торговли: Спотовая")}</p>
              </div>
            </div>
            <Link to="/register" className="main-page-button-link btn">
              {t("Подключить")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
});

export { Algoritms };
