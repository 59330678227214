class EventEmitter {
    constructor() {
      this.events = {};
    }
  
    /**
     * @param {string} eventName
     * @param {Function} callback
     */
    subscribe(eventName, callback) {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(callback);
    }
  
    /**
     * @param {string} eventName
     * @param {Function} callback
     */
    unsubscribe(eventName, callback) {
      if (this.events[eventName]) {
        this.events[eventName] = this.events[eventName].filter(
          (eventCallback) => callback !== eventCallback
        );
      }
    }
  
    /**
     * @param {string} eventName
     * @param {any} args
     */
    emit(eventName, args) {
      const event = this.events[eventName];
      if (event) {
        event.forEach((callback) => callback.call(null, args));
      }
    }
  }
  
  const websocketEmitter = new EventEmitter();

  export { websocketEmitter };
  