import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { websocketEmitter } from "../../helpers/EventEmitter";
import { BinaryChartLoader } from "./BinaryChartLoader";
export const BidsTable = ({ symbol }) => {
  const { t } = useTranslation();

  const [bidsData, setBidsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Function to handle incoming bids data
    const handleBidsUpdate = (bids) => {
      setBidsData(bids);
      setIsLoading(false); // Set loading to false once data is received
    };

    // Subscribe to 'onBidsDataMessage' event for real-time updates
    websocketEmitter.subscribe("onBidsTableDataMessage", handleBidsUpdate);

    // Cleanup function to unsubscribe when component unmounts or symbol changes
    return () => {
      websocketEmitter.unsubscribe("onBidsTableDataMessage", handleBidsUpdate);
    };
  }, [symbol]); // Dependency on symbol to re-subscribe if symbol changes

  return (
    <div className="transaction-history-table-wrapper">
      <div className="table-inner-wrapper">
        {isLoading && <BinaryChartLoader/>}
        {!isLoading && (
          <table>
            <thead>
              <tr className="bids-table">
                <th>{t("Bids")}</th>
                <th>{t("Asks")}</th>
              </tr>
            </thead>

              {bidsData.bids &&
                bidsData.bids.length > 0 &&
                bidsData.asks &&
                bidsData.asks.length > 0 && (
                  <tbody>
                    {bidsData.bids.map((bid, idx) => (
                      <tr key={bid[1] + idx + "bid"} className="bids-table">
                        <td className="bid">{bid[0]}</td>
                        <td className="bid">{bid[1]}</td>
                        {bidsData.asks[idx] && (
                          <>
                            <td className="ask">{bidsData.asks[idx][0]}</td> {/* Ask Price */}
                            <td className="ask">{bidsData.asks[idx][1]}</td>{" "}
                            {/* Ask Quantity */}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}

          </table>
        )}
      </div>
    </div>
  );
};
