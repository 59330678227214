// function receive attribure of type string e.g. "1591261500000" and returns Output: "20:50"
// use inside VictoryCryptoChart => VictoryAxis component as prop for tickFormat

const formatTimestamp = (timestamp, interval) => {
  const date = new Date(timestamp);

  // Define days of the week array
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Determine the time format based on the interval
  let timeFormat;
  switch (interval) {
    case "1m":
    case "3m":
    case "5m":
    case "15m":
    case "30m":
    case "1h":
    case "2h":
    case "4h":
    case "6h":
    case "8h":
    case "12h":
      timeFormat = `${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      break;
    case "1d":
    case "3d":
    case "1w":
      timeFormat = `${daysOfWeek[date.getDay()]}`;
      break;
    case "1M":
      timeFormat = `${date.getMonth() + 1}/${date.getFullYear()}`;
      break;
    default:
      timeFormat = `${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
  }

  return timeFormat;
};
// const formatTimestamp = (timestamp, zoomDomain) => {
//   const date = new Date(timestamp);
//   const timeDiff = zoomDomain.x[1] - zoomDomain.x[0];

//   // Determine the appropriate format based on the time difference in milliseconds
//   if (timeDiff <= 1000 * 60 * 60) {
//     // Show minutes and hours for small time ranges
//     const hours = date.getHours();
//     const minutes = date.getMinutes().toString().padStart(2, "0");
//     return `${hours}:${minutes}`;
//   } else if (timeDiff <= 1000 * 60 * 60 * 24) {
//     // Show dates for larger time ranges
//     const day = date.getDate();
//     const month = date.toLocaleString("default", { month: "short" });
//     return `${month} ${day}`;
//   } else {
//     // Show months for even larger time ranges
//     const month = date.toLocaleString("default", { month: "short" });
//     return month;
//   }
// };



export { formatTimestamp };
