import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";

export const PercentageBlock = ({ selectedPair }) => {
  const { data } = useGetUserDataQuery();
  return (
    <div className="percentage-block">
      <p>{selectedPair}</p>
      <span className="percentage">{data?.user_level_percentage + " %"}</span>
    </div>
  );
};
