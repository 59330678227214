import { useTranslation } from "react-i18next";
import { ReactComponent as Success } from "../../assets/icons/modals/success.svg";
import { ReactComponent as Warning } from "../../assets/icons/modals/warning.svg";
import { ReactComponent as Failure } from "../../assets/icons/modals/failure.svg";

import { NextButton } from "./NextButton";
export function Popup({
  title = "Поздравляем!",
  description = "Ваша заявка в обработке",
  switchDone,
  type = "success",
  btnText = "OK",
}) {
  const { t } = useTranslation();

  return (
    <div className="popup-wrapper">
      <div className="popup-icon-thumb">
        {type === "success" && <Success />}
        {type === "warning" && <Warning />}
        {type === "failure" && <Failure />}
      </div>

      <h2 className="popup-status-title">{t(title)}</h2>

      <p className="popup-description">{t(description)}</p>
      <NextButton onClick={switchDone} text={btnText} />
    </div>
  );
}
