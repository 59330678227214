import { useState } from "react";
import { useTranslation } from "react-i18next";

import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { CustomSelect } from "./CustomSelect";

export default function OptionsTable({ transactionsHistory }) {
  const [perPage, setPerPage] = useState(5);


  const { t } = useTranslation();

  const handleDisplayPerPage = (count = null) => {
    setPerPage(count);
  };
  const transformTypeName = (typeName) => {
    let renamed;
    switch (typeName) {
      case "withdrawal":
        renamed = "out";
        break;
      case "deposit":
        renamed = "in";
        break;
      case "balance_transaction":
        renamed = "transfer";
        break;
      default:
        return "invalid type";
    }
    return renamed;
  };
  return (
    <>
      <div className="transactions-section__table-wrapper">
        <table className="transaction-section__table">
          <thead>
            <tr className="transaction-section__table-heading-row rel">
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("id")}</span>
                </div>
              </th>
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("Тип")}</span>
                </div>
              </th>
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("Сумма")}</span>
                </div>
              </th>
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("Валюта")}</span>
                </div>
              </th>
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("Статус")}</span>
                </div>
              </th>
              <th>
                <div className="transactions-section__table-td-wrapper">
                  <span>{t("Дата")}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionsHistory &&
              transactionsHistory?.length > 0 &&
              [...transactionsHistory]
                .reverse()

                .splice(0, perPage || transactionsHistory.length)
                .map((transaction, index) => (
                  <tr
                    key={index}
                    className="transaction-section__table-body-row"
                  >
                    <td>{transaction.id}</td>
                    <td>{t(transformTypeName(transaction.type))}</td>
                    <td>{transaction.amount}</td>
                    <td>
                      {transaction.from_ticker &&
                        transaction.from_ticker.toUpperCase()}
                    </td>
                    <td
                      className={(() => {
                        let classname;
                        switch (transaction.status) {
                          case "Approved":
                            classname = "approved";
                            break;
                          case "Pending":
                            classname = "pending";
                            break;
                          case "Rejected":
                            classname = "rejected";
                            break;
                          default:
                            console.log("unsupported type");
                        }
                        return classname;
                      })()}
                    >
                      {transaction.status}
                    </td>
                    <td>
                      {transaction.type !== "balance_transaction"
                        ? transformDateTimeForSessionsTable(transaction.date)
                        : transformDateTimeForSessionsTable(
                            transaction.timestamp
                          )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <CustomSelect
        text={t("На странице")}
        handleDisplayPerPage={handleDisplayPerPage}
        perPage={perPage}
      />
    </>
  );
}
