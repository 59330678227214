import { useTranslation } from "react-i18next";

export const Preview = ({ previewFile, handleDeleteFile }) => {
  const { t } = useTranslation();
  return (
    <div className="form-container--kys-preview">
      <div className="form-container--kys-image-thumb">
        <img
          src={previewFile}
          alt="Превью изображения"
          width={180}
          height={140}
        />
      </div>
      <button
        type="button"
        className="KYS-section__delete-btn"
        onClick={handleDeleteFile}
      >
        {t("Удалить файл")}
      </button>{" "}
    </div>
  );
};
