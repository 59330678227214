import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { useEffect } from "react";

export const OptionsBalanceBlock = ({betHistory}) => {

  const { data,refetch } = useGetUserDataQuery();
  useEffect(() => {
    refetch();

  }, [refetch, data, betHistory]);



  return (
    <ul className="options-balance-list">
      <li key="options-balance-1">
        {"BTC: " + data?.main_crypto_balance_btc}
        <span>{" BTC"}</span>
      </li>
      <li key="options-balance-2">
        {"ETH: " + data?.main_crypto_balance_eth}
        <span>{" ETH"}</span>
      </li>
      <li key="options-balance-3">
        {"USDT: " + data?.options_crypto_balance_usdt}
        <span>{" USDT"}</span>
      </li>
    </ul>
  );
};
