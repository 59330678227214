import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { transportKissFields } from "../../toolkitReducers/actions.slice";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../hooks/useCountries";

function KYCPage() {
  const [formData, setFormData] = useState({
    name: { value: "", required: true, valid: false },
    phone: { value: "", required: false, valid: true },
    surname: { value: "", required: false, valid: false },
    birthday: { value: "", required: true, valid: false },
    telegram: { value: "", required: true, valid: true },
    country: { value: "", required: true, valid: false },
    city: { value: "", required: false, valid: true },
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries } = useCountries();

  // Состояние для хранения ошибок
  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    surname: false,
    birthday: false,
    telegram: false,
    country: false,
    city: false,
  });

  const validateForm = () => {
    const newErrors = {};

    // Проверяем каждое поле на пустоту
    for (const field in formData) {
      const { value, required, valid } = formData[field];
      if (
        (required && value.trim() === "") ||
        (required && !valid) ||
        (!required && !valid)
      ) {
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    }

    setErrors(newErrors);

    // Проверяем, есть ли хотя бы одна ошибка
    return Object.values(newErrors).every((error) => !error);
  };

  const goToStep2 = () => {
    if (validateForm()) {
      navigate("/profile/kyc/step2");
      dispatch(transportKissFields(formData));
    }
  };

  // Обработчик изменения для инпутов
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        valid: event.target.validity.valid,
      },
    });
    setErrors({
      ...errors,
      [name]: !event.target.validity.valid,
    });
  };

  return (
    <div className="tabs__accordions-wrapper">
      <div
        className="tabs__list"
        id="tabpanel-1"
        role="tabpanel"
        tabIndex="0"
        aria-labelledby="tab-1"
      >
        <div className="KYS-section__inputs-wrapper">
          <h2 className="KYS-section__inputs-heading">
            {t("Общая Информация")}
          </h2>

          <div className="KYS-section__inputs-row">
            <div className="form-container">
              <label htmlFor="name">{t("Имя")}</label>
              <input
                required={formData.name.required}
                type="text"
                placeholder={t("Jacob")}
                name="name"
                id="name"
                value={formData.name.value}
                onChange={handleInputChange}
                className={errors.name ? "error" : ""}
                pattern="^(?=.*[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ])[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ\s]{3,32}$"
              />
              {errors.name && (
                <span className="error-message">
                  {t(
                    "Please enter a name with at least one alphabetical character and between 3 and 32 characters in length."
                  )}
                </span>
              )}
            </div>
            <div className="form-container">
              <label htmlFor="surname">{t("Фамилия")}</label>
              <input
                required={formData.surname.required}
                type="text"
                placeholder={t("Mercer")}
                name="surname"
                id="surname"
                value={formData.surname.value}
                onChange={handleInputChange}
                className={errors.surname ? "error" : ""}
              />
              {errors.surname && (
                <span className="error-message">
                  {t(
                    "Please enter a surname with at least one alphabetical character and between 3 and 32 characters in length."
                  )}
                </span>
              )}
            </div>
          </div>

          <div className="KYS-section__inputs-row">
            <div className="form-container">
              <label htmlFor="phone">{t("Номер телефона")}</label>
              <input
                type="tel"
                placeholder={t("+74951234567")}
                name="phone"
                id="phone"
                value={formData.phone.value}
                onChange={handleInputChange}
                pattern="^\+\d{1,3}\s?\d{10,15}$"
              />
              {errors.phone && (
                <span className="error-message">
                  {t(
                    "Please enter a valid phone number. It should start with a plus sign (+) followed by the country code and phone number digits."
                  )}
                </span>
              )}
            </div>

            <div className="form-container">
              <label htmlFor="birthday">{t("Дата Рождения *")}</label>
              <input
                required={formData.birthday.required}
                type="date"
                name="birthday"
                id="birthday"
                min="1924-01-01"
                max="2007-01-01"
                value={formData.birthday.value}
                onChange={handleInputChange}
                className={errors.birthday ? "error" : ""}
              />
              {errors.birthday && (
                <span className="error-message">
                  {t(
                    "Please enter a valid date of birth in the format dd.mm.yyyy"
                  )}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="KYS-section__inputs-wrapper">
          <h2 className="KYS-section__inputs-heading">
            {t("Контактная информация")}
          </h2>

          <div className="KYS-section__inputs-row">
            <div className="form-container">
              <label htmlFor="telegram">{t("Telegram *")}</label>
              <input
                required={formData.telegram.required}
                type="text"
                placeholder={t("t.me/myTelegramProfile")}
                name="telegram"
                id="telegram"
                value={formData.telegram.value}
                onChange={handleInputChange}
                pattern="^(?:https?:\/\/)?(?:www\.)?(?:t\.me\/|telegram\.me\/)?@?[a-zA-Z0-9_]{5,}$"
                className={errors.telegram ? "error" : ""}
              />
              {errors.telegram && (
                <span className="error-message">
                  {t(
                    "Please enter a valid Telegram link, e.g., t.me/username or @username."
                  )}
                </span>
              )}
            </div>
          </div>

          <div className="KYS-section__inputs-row">
            <div className="form-container">
              <label htmlFor="country">{t("Страна *")}</label>
              <select
                name="country"
                id="country"
                value={formData.country.value}
                onChange={handleInputChange}
              >
                <option value="" disabled key={"xxx111"}>
                  Select a country
                </option>
                {countries.map(({ name, Iso2 }, idx) => (
                  <option value={Iso2} key={name + idx}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-container">
              <label htmlFor="country">{t("Город")}</label>
              <input
                required={formData.city.required}
                type="text"
                placeholder={t("London")}
                name="city"
                id="city"
                value={formData.city.value}
                onChange={handleInputChange}
                className={errors.city ? "error" : ""}
              />
              {errors.city && (
                <span className="error-message">{t("Заполните поле")}</span>
              )}
            </div>
          </div>
        </div>

        <button
          className="KYS-section__next-page btn btn--primary"
          onClick={goToStep2}
          type="button"
        >
          {t("Далее")}
        </button>
      </div>
    </div>
  );
}

export default KYCPage;
