import React, { useEffect, useState, useRef } from "react";
import { useSetRisksMutation, useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Popup } from "../../Elements/Popup";
import DepositSuccess from "../Algoritms/DepositSuccess";
import Table from "../Algoritms/Table";
import { useGetRisksQuery } from "../../toolkitReducers/cryptouch";
import { selectTheme } from "../../toolkitReducers/selectors";
import { PlanSwitcher } from "../Algoritms/PlanSwitcher";
export default function SettingPage() {
  const [isDone, setDone] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
    const [plan, setPlan] = useState("base");
  const { data: risks } = useGetRisksQuery();
  const {data:userData} = useGetUserDataQuery()
  const theme = useSelector(selectTheme);
  const [isPercentRists, setIsPercentRisk] = useState(
    setChecks(localStorage.getItem("current_risk") || 0)
  );
  const currentRisk = risks?.[risks.length - 1]?.current_risk;

  const [percentage, setPercentage] = useState(
    currentRisk || localStorage.getItem("current_risk") || 0
  );
  const [setRisks] = useSetRisksMutation();
  const error = useSelector((state) => state.state.error);

  const initialized = useRef(false);
  const rangeRef = useRef(null);

  const [sliderFillPercentage, setSliderFillPercentage] = useState(
    localStorage.getItem("slider_fill") || 0
  );
  const { t } = useTranslation();
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {
      setModalOpen(false);
    }
  };
  const handleSelectPlan = (plan) => setPlan(plan);
  const switchDone = (bool) => {
    setDone(bool);
  };

  const closePopup = () => {
    switchDone(false);
    setModalOpen(false);
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  });

  useEffect(() => {
    if (initialized.current && currentRisk && rangeRef.current) {
      setPercentage(currentRisk);
      setIsPercentRisk(setChecks(currentRisk));
      setSliderFillPercentage(
        ((currentRisk - rangeRef.current.min) /
          (rangeRef.current.max - rangeRef.current.min)) *
          100
      );
    }
  }, [currentRisk]);

  const handlePercentageChange = ({ target }) => {
    setPercentage(target.value);
    setIsPercentRisk(setChecks(target.value));
    setSliderFillPercentage(
      ((target.value - target.min) / (target.max - target.min)) * 100
    );
    localStorage.setItem("slider_fill", sliderFillPercentage);
  };

  function setChecks(value) {
    switch (true) {
      case value >= 75:
        return 3;
      case value >= 55:
        return 3;
      case value >= 35 && value <= 55:
        return 2;
      case value >= 0 && value <= 35:
        return 1;
      case value === 0:
        return 0;
      default:
        return 0;
    }
  }

  const clickFaq = () => {

    // if is_pro_user || is_combo_user
    setRisks({ current_risk: percentage });
    localStorage.setItem("current_risk", percentage);
    localStorage.setItem("slider_fill", sliderFillPercentage);

    setModalOpen(true);
  };

  return (
    <div className="page__sections-wrapper medium-wrapper">
      <section
        className={
          theme === "light" ? "details-section" : "details-section dark-profile"
        }
        data-limit="2000"
      >
        <h1 className="details-section__main-heading h3">
          {t("Настройка риск-доходность")}:
        </h1>

        <form className="details-section__wrapper form details-section__form rel">
          <div className="details-section__progress-bar-wrapper">
            <div className="details-section__progress-bar">
              <div className="form-container form-container--range range">
                <ul className="range__list">
                  <li className="range__list-item">25%</li>
                  <li className="range__list-item">35%</li>
                  <li className="range__list-item">45%</li>
                  <li className="range__list-item">55%</li>
                  <li className="range__list-item">65%</li>
                  <li className="range__list-item">75%</li>
                </ul>
                <input
                  type="range"
                  value={percentage}
                  onChange={handlePercentageChange}
                  id="percent"
                  name="royalty"
                  min="25"
                  max="75"
                  step="10"
                  disabled={isDisabled ? true : false}
                  ref={rangeRef}
                  style={{
                    backgroundSize: `${sliderFillPercentage}% 100%, 100% 100%`,
                  }}
                  className={`${isDisabled && "disabled"} `}
                />
              </div>
              <ul className="range-points-container">
                <li
                  className={
                    sliderFillPercentage >= 20
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "20%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 40
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "40%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 60
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "59%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 80
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "79%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 100
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "99%" }}
                ></li>
              </ul>
            </div>

            <p className="details-section__progress-bar-description">
              *
              {t(
                "Увеличение доходности влечет за собой риски утери средств. Использовать осторожно.."
              )}
            </p>
          </div>

          <div className="details-section__footer">
            <div className="details-section__footer-column">
              <h3 className="details-section__footer-column-heading bold">
                {t("Контроль риска:", { value: 1 })}
              </h3>

              <div className="details-section__footer-column-checkboxes">
                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    data-min-price="1500"
                    className="form__custom-checkbox disabled-checkbox"
                    type="checkbox"
                    name="accept"
                    checked={isPercentRists > 0 && true}
                    id="controlLvl1-1"
                  />

                  <label htmlFor="controlLvl1-1">
                    {t("Разрешить длинные цепочки обмена")}
                  </label>
                </div>

                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    checked={isPercentRists > 0 && true}
                    data-min-price="1500"
                    className="form__custom-checkbox"
                    type="checkbox"
                    name="accept"
                    id="controlLvl1-2"
                  />

                  <label htmlFor="controlLvl1-2">
                    {t("Разрешить использовать новые токены")}
                  </label>
                </div>
              </div>
            </div>

            <div className="details-section__footer-column">
              <h3 className="details-section__footer-column-heading bold">
                {t("Контроль риска:", { value: 2 })}
              </h3>

              <div className="details-section__footer-column-checkboxes">
                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    checked={isPercentRists > 1 && true}
                    data-min-price="3000"
                    className="form__custom-checkbox"
                    type="checkbox"
                    name="accept"
                    id="controlLvl2-1"
                  />

                  <label htmlFor="controlLvl2-1">
                    {t("Разрешить использовать новые обменные площадки")}
                  </label>
                </div>

                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    checked={isPercentRists > 1 && true}
                    data-min-price="3000"
                    className="form__custom-checkbox"
                    type="checkbox"
                    name="accept"
                    id="controlLvl2-2"
                  />

                  <label htmlFor="controlLvl2-2">
                    {t("Не использовать классические пары")}
                  </label>
                </div>
              </div>
            </div>

            <div className="details-section__footer-column">
              <h3 className="details-section__footer-column-heading bold">
                {t("Контроль риска:", { value: 3 })}
              </h3>

              <div className="details-section__footer-column-checkboxes">
                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    data-min-price="5000"
                    checked={isPercentRists > 2 && true}
                    className="form__custom-checkbox"
                    type="checkbox"
                    name="accept"
                    id="controlLvl3-1"
                  />

                  <label htmlFor="controlLvl3-1">
                    {t("Использовать только новые токены")}
                  </label>
                </div>

                <div className="form-container--checkbox checkbox">
                  <input
                    disabled
                    checked={isPercentRists > 2 && true}
                    data-min-price="5000"
                    className="form__custom-checkbox"
                    type="checkbox"
                    name="accept"
                    id="controlLvl3-2"
                  />

                  <label htmlFor="controlLvl3-2">
                    {t("Использовать только свопы")}
                  </label>
                </div>
              </div>
            </div>

            <div className="details-section__footer-column"></div>
          </div>
          {error && <div className="error-container">{error?.message}</div>}
          <button
            disabled={!userData?.is_pro_user && !userData?.is_combo_user}
            type="button"
            onClick={clickFaq}
            className="details-section__form-submit-btn"
          >
            {t("Применить")}
          </button>
        </form>
        <Popup
          props={{
            expanded: modalOpen,
            onModalClose: closeModal,
          }}
          children={
            <DepositSuccess
              title={t("Поздравляем!")}
              description={t("Риски успешно установлены!")}
              switchDone={closePopup}
            />
          }
        />
      </section>

      {/* <section className="details-page__transaction-section" data-limit="2000">
        <HistoryTable />
      </section> */}
      <div className="algorithms-page">
        <section
          className={
            theme === "light"
              ? "algorithms-history"
              : "algorithms-history dark-profile"
          }
        >
          <div className="tabs__accordions-wrapper">
            <div
              className="tabs__list"
              // id="tabpanel-1"
              role="tabpanel"
              tabIndex="0"
              aria-labelledby="tab-1"
            >
              <div className="table-title-wrapper">
                <h2>{t("Профит")}</h2>
                <PlanSwitcher handleSelectPlan={handleSelectPlan} plan={plan} />
              </div>
              <div className="algorithms-history__table-wrapper">
                <Table plan={plan} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
