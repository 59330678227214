import {ReactComponent as BTC} from '../../assets/icons/hero/btc.svg'
import {ReactComponent as ETH} from '../../assets/icons/hero/eth.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/hero/arrow-up.svg'
import {ReactComponent as ArrowDown} from '../../assets/icons/hero/arrow-down.svg'


const CurrencyCard = ({currency, course, price}) => {

    return (
        <div className='hero-coin-card'>
            {currency === "BTC" ? <BTC/> : <ETH/>}
            <div className='holder'>
                <p className='currency-title'>{currency === "BTC" ? "Bitcoin" : "Ethereum"}</p>
                <div className='rate-holder'>
                    {course > 0 ? <ArrowUp/> : <ArrowDown/>}
                    <span className={course > 0 ? 'currency-course' : 'currency-course drop'}>{course && course}</span>
            </div>
            </div>
            <div className='holder'>
                <p className='currency-rate'>{price && Number(price).toFixed(2)} $</p>
                <p className='currency-title'>{currency}</p>
            </div>
        </div>
    )
}

export {
    CurrencyCard
}

