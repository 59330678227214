import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeft } from "../../assets/icons/development-section/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/development-section/arrow-right.svg";
import { ReactComponent as Arrow } from "../../assets/icons/development-section/arrow.svg";
import { useState} from "react";
const Development = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [translateValue, setTranslateValue] = useState(0);
  const [startX, setStartX] = useState(null);
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;
    const currentX = e.touches[0].clientX;
    const diff = currentX - startX;

    if (
      (translateValue >= 0 && diff > 0) ||
      (Math.abs(translateValue) >= 4000 && diff < 0)
      // this condition limits swipe when one of container edges has been reached.
      // Improve further to solve overswipe issue occuring on small displays 375px
    )
      return;
    if (Math.abs(diff) > 50) {
      setTranslateValue((prevTranslate) => prevTranslate + diff);
      setStartX(currentX);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };
  const handleScroll = (value) => {
    if (Math.abs(translateValue) >= 4200) {
      return;
    }

    setTranslateValue(translateValue + value);
  };

  return (
    <section
      ref={ref}
      className="main-page-section development-section"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="section-inner-container">
        <div className="development-upper-holder">
          <h3>{t("Карта развития")} CrypTouch</h3>
          <div className="arrows-thumb">
            <button
              className="arrow-button rel"
              onClick={() => setTranslateValue(0)}
            >
              <ArrowLeft />
            </button>
            <button
              className="arrow-button rel"
              onClick={() => handleScroll(-200)}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
        <div
          className="development-lower-holder"
          style={{ transform: `translateX(${translateValue}px)` }}
        >
          <div className="development-card">
            <span>2020 {t("Год")}</span>
            <p>
              {t(
                "начало работы над AI для выявления потенциальных возможностей для арбитража"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>2021 {t("Год")}</span>
            <p>
              {t("начало разработки системы для закрытия выявленных сделок")}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>2022 {t("Год")}</span>
            <p>
              {t(
                "на пре-альфа версии количество успешных сделок достигло 76,4%. Подготовка к разработке платформы"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2023 1 квартал:")}</span>
            <p>
              {t(
                "на пре-альфа версии количество успешных сделок достигло 76,4%. Подготовка к разработке платформы"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2023 3-4 квартал:")}</span>
            <p>{t("релиз платформы cryptouch")}</p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2024 1 квартал:")}</span>
            <p>
              {t(
                "запуск собственного торгуемого токена для унификации выплат по успешным операциям"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2024 3 квартал:")}</span>
            <p>{t("публикация токена на 10+ платформах")}</p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2024 4 квартал:")}</span>
            <p>
              {t(
                "релиз модуля “early bird”, позволяющего автоматическое раннее приобретение свежих размещенных монет и токенов для любителей высокорисковых операций"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2025 2 квартал:")}</span>
            <p>
              {t("выпуск версии AI 2.0 с процентом успешности сделок до 92%")}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2025 4 квартал:")}</span>
            <p>
              {t(
                "подготовка к выходу на IPO и распределение части ацкий активным пользователям, зарегистрированными и активными до релиза AI 2.0 в качестве признательности за доверие"
              )}
            </p>
            <Arrow />
          </div>
          <div className="development-card">
            <span>{t("2026")}</span>
            <p>{t("future updates")}</p>
            <Arrow />
          </div>
        </div>
      </div>
    </section>
  );
});

export { Development };
