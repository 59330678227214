import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as VideoPlay } from "../../assets/icons/promo-section/video-play.svg";
import PromoChart from "../../assets/icons/promo-section/promo-chart.png";
const Promo = forwardRef(({refVideo}, ref) => {
  const { t } = useTranslation();
  return (
    <section ref={ref} className="main-page-section promo-section">
      <div className="section-inner-container">
        <div className="promo-section-left-side">
          <h2>
            {t("CrypTouch: Революция в разработке на базе TensorFlow 2.0")}
          </h2>
          <p className="promo-section-description">
            {t(
              "CrypTouch представляет собой инновационный проект, разработанный с использованием передовой технологии TensorFlow 2.0, выпущенной в 2019 году."
            )}
          </p>
          <div className="promo-section-video-wrapper">
            <Link onClick={() => {
              refVideo.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
            }}>
              <VideoPlay />
            </Link>
            <div>
              <p className="watch-video-title">{t("Смотреть видео")}</p>
              <p>{t("Откройте для себя мир возможностей с CrypTouch!")}</p>
            </div>
          </div>
        </div>
        <div className="promo-section-right-side">
          <h3>{t("Показатели успешных сделок:")}</h3>
          <p>{t("Наша цель - достичь 84-87% до конца 2024 года.")}</p>

          <div className="thumb">
          <img
            src={PromoChart}

            className="hero-coin-card"
            alt="etc-card"
          />
          </div>
        </div>
      </div>
    </section>
  );
});

export { Promo };
