import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export const TimeControls = ({
  getFormTime,
  setErrors,
  handleError,
  removeError,
}) => {
  const [time, setTime] = useState(0);
  const timeInputRef = useRef(null);
  const { t } = useTranslation();

  const handleTimeChange = (e) => {
    if (e.target.value < 0) {
      return;
    }
    if (e.target.value === 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "время не может равняться нулю")
      );
    } else {
      removeError("время не может равняться нулю");
    }
    setTime(e.target.value);
    getFormTime(time);
  };
  const incrementTime = () => {
    removeError("время не может равняться нулю");
    setTime((prevTime) => prevTime + 1);
    getFormTime(time + 1);
  };
  const decrementTime = () => {
    if (time === 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "время не может равняться нулю")
      );
      return;
    }
    setTime((prevTime) => (prevTime === 0 ? prevTime : prevTime - 1));
    getFormTime(time - 1);
  };

  return (
    <div className="controls-wrapper">
      <label>
        {" "}
        {t("Время")} ({t("минуты")}):{" "}
      </label>
      <div className="controls-input-wrapper">
        <button onClick={decrementTime} disabled>-</button>
        <input
          type="number"
          ref={timeInputRef}
          value={time}
          min={0}
          onChange={handleTimeChange}
        />
        <button onClick={incrementTime} disabled>+</button>
      </div>
    </div>
  );
};
