import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
  },
};

const ReferalsBarChart = ({ referals }) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme)

  const totalIncome =
    (referals &&
      referals?.top_referrals &&
      referals?.top_referrals.length > 0 &&
      referals?.top_referrals.reduce(
        (acc, el) => el.total_deposits + acc,
        0
      )) ||
    0;


  const data = {
    labels: Array(5)
      .fill()
      .map((_, idx) => `User ${idx + 1}`),
    datasets: [
      {
        label: "Referals income",
        data: referals?.top_referrals?.map((ref, idx) => ref.total_deposits),
        backgroundColor:
          theme === "light" ? "rebeccapurple" : "rgba(125, 103, 255, 1)",
        borderRadius: 10,
        borderSkipped: false,
        maxBarThickness: 40,
      },
    ],
  };

  return (
    <>
      <div className="refferals-chart-heading-wrapper gradient-wrapper">
        <p>{t("Общий оборот")}:</p>
        <span>{`${totalIncome?.toFixed(2)} USDT`}</span>
      </div>
      <Bar data={data} options={options} />
    </>
  );
};

export { ReferalsBarChart };
