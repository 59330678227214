function transformDataForWeeklyChart(type, otherProps) {
    let datasets;

    switch (type) {
      case "bots-base":
        datasets = [
          {
            label: "USDT",
            data: otherProps?.data?.map((e) => e[0]),
            fill: false,
            borderColor: "green",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "green",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
          {
            label: "BTC",
            data: otherProps?.data?.map((e) => e[1]),
            fill: false,
            borderColor: "red",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "red",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
          {
            label: "ETH",
            data: otherProps?.data?.map((e) => e[2]),
            fill: false,
            borderColor: "blue",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "blue",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
        ];
        break;
      case "bots-pro":
        datasets = [
          {
            label: "USDT",
            data: otherProps?.data?.map((e) => e[3]),
            fill: false,
            borderColor: "green",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "green",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
          {
            label: "BTC",
            data: otherProps?.data?.map((e) => e[4]),
            fill: false,
            borderColor: "red",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "red",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
          {
            label: "ETH",
            data: otherProps?.data?.map((e) => e[5]),
            fill: false,
            borderColor: "blue",
            borderWidth: 2,
            tension: 0,
            pointStyle: "triangle",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "blue",
            pointBorderWidth: 1,
            pointRadius: 7,
            pointHoverRadius: 10,
          },
        ];
        break;
    case "bots-combo":
            datasets = [
              {
                label: "USDT",
                data: otherProps?.data?.map((e) => e[6]),
                fill: false,
                borderColor: "green",
                borderWidth: 2,
                tension: 0,
                pointStyle: "triangle",
                pointBackgroundColor: "#ffffff",
                pointBorderColor: "green",
                pointBorderWidth: 1,
                pointRadius: 7,
                pointHoverRadius: 10,
              },
              {
                label: "BTC",
                data: otherProps?.data?.map((e) => e[7]),
                fill: false,
                borderColor: "red",
                borderWidth: 2,
                tension: 0,
                pointStyle: "triangle",
                pointBackgroundColor: "#ffffff",
                pointBorderColor: "red",
                pointBorderWidth: 1,
                pointRadius: 7,
                pointHoverRadius: 10,
              },
              {
                label: "ETH",
                data: otherProps?.data?.map((e) => e[8]),
                fill: false,
                borderColor: "blue",
                borderWidth: 2,
                tension: 0,
                pointStyle: "triangle",
                pointBackgroundColor: "#ffffff",
                pointBorderColor: "blue",
                pointBorderWidth: 1,
                pointRadius: 7,
                pointHoverRadius: 10,
              },
            ];
            break;
            
        default: console.log("unsupported dataset type");
            
    }
    return datasets;


}

export {
    transformDataForWeeklyChart
}