import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Nodes } from "../../assets/icons/partnership-section/nodes.svg";
import { Link } from "react-router-dom";

const promotions = [
  "Начните зарабатывать до 10%, активировав алгоритмы от 50$",
  "Стабильный доход: Зарабатывайте до 500$ в месяц, привлекая новых клиентов.",
  "Еженедельные выплаты: Получайте ваш заработок еженедельно.",
];

const Partnership = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <section ref={ref} className="main-page-section partnership-section">
      <div className="section-inner-container">
        <div className="partnership-left-side">
                  <div>
          <h3>{t("Партнерская программа")}</h3>
          <p className="partnership-description">
            {t(
              "Станьте частью нашей партнерской программы совершенно бесплатно и начните зарабатывать уже сегодня."
            )}
          </p>
        </div>
        <ul className="partnership-lower-row">
          {promotions.map((text, idx) => (
            <li className="content-thumb rel" key={idx}>
              <div className="logo">
                <Nodes />
              </div>
              <p>{t(text)}</p>
            </li>
          ))}
        </ul>
          <Link to="/register" className="main-page-button-link btn">{t("Регистрация")}</Link>
        </div>
        <div className="partnership-right-side"></div>
      </div>
    </section>
  );
});

export { Partnership };
