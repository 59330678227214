export const PlanSwitcher = ({ handleSelectPlan, plan }) => {
  const plans = ["base", "pro", "combo"];
  return (
    <div className="plan-switcher">
      {plans.map((planName, idx) => (
        <button
          key={planName + idx}
          onClick={() => handleSelectPlan(planName)}
          className={plan === planName ? "selected" : ""}
        >
          {planName.charAt(0).toUpperCase() +
            planName.slice(1, planName.length)}
        </button>
      ))}
    </div>
  );
};
