import { ReactComponent as Up } from "../../assets/icons/binary-option/up-bet.svg";
import { ReactComponent as Down } from "../../assets/icons/binary-option/down-bet.svg";
import { useTranslation } from "react-i18next";
export const BettingControls = (props) => {
  const { startBinary } = props;
  const { t } = useTranslation();
  return (
    <div className="betting-controls-wrapper">
      <button
        disabled
        onClick={() => startBinary("up")}
        className="betting-button"
      >
        {t("Купить")}
        <span>
          <Up />
        </span>
      </button>

      <div className="payout-block">
        <p> {t("Ваша выплата")}: </p>
        <span> {props.payout || 0} USDT</span>
      </div>
      <button
        disabled
        onClick={() => startBinary("down")}
        className="betting-button red"
      >
        {t("Продать")}
        <span>
          <Down />
        </span>
      </button>
    </div>
  );
};
