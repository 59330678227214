import { ReactComponent as PassportIcon } from "./pasportIcon.svg";
import { ReactComponent as UploadIcon } from "./uploadIcon.svg";
import { ReactComponent as IdCardIcon } from "./idCardIcon.svg";
import { useTranslation } from "react-i18next";

export const UploadPanel = ({ type, handleUpload, handleFileChange }) => {
  const { t } = useTranslation();

  return (
    <div className="form-container form-container--image rel">
      <div className="form-container--kys-placeholder">
        <div className="form-container--placeholder-icon-wrapper">
          {type === "passport" ? <PassportIcon /> : <IdCardIcon />}
        </div>

        <p className="form-container--placeholder-title">
          {t(type === "passport" ? "Пасспорт" : "ID-Карта")}
        </p>
        <label htmlFor="file-input-1" className="KYS-section__upload-btn">
          <UploadIcon />
          <span onClick={handleUpload}>{t("ЗАГРУЗИТЬ")}</span>
        </label>
        <input
          type="file"
          id={type === "passport" ? "file-input-1" : "file-input-2"}
          name={type === "passport" ? "passport" : "id-card"}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};
