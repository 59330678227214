
import { useState } from "react";
import { Popup } from "./Popup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectError } from "../../toolkitReducers/selectors";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { TradingOffer } from "./TradingOffer";
import { ModalWindow } from "./ModalWindow";
import { checkFundsToLaunchBot } from "../../helpers/checkUserFunds";
import { pickSuccessMsg } from "../../helpers/pickSuccessMsg";
import { useStartAlgorythmMutation } from "../../toolkitReducers/cryptouch";
import { ReplenishBotBalance } from "./ReplenishBotBalance";
export const BotsSection = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { t } = useTranslation();
  const [promotion, setPromotion] = useState(null);
  const [popupDescription, setPopupDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupType, setPopupType] = useState("");
  const error = useSelector(selectError);
  const [startAlgorythm] = useStartAlgorythmMutation();
  const { data: userData } = useGetUserDataQuery();

  const handleSuccessfulLaunch = (tariff, amount, currency) => {
    startAlgorythm({ tariff, amount, currency });
    if (!error) {
      setIsModalOpen(false);
      setPopupType("success");
      setPopupTitle("Поздравляем");
      setPopupDescription(pickSuccessMsg(tariff, userData));
      setIsOpenPopup(true);
    }
  };

  const resetState = () => {
    setIsOpenPopup(false);
    setIsModalOpen(false);
    setPromotion(null);
    setPopupDescription("");
    setPopupTitle("");
    setPopupType("");
  };

  const handleInsufficientFunds = (promotion) => {
    setPromotion(promotion);

    if (!checkFundsToLaunchBot(promotion, userData)) {
      setPopupType("failure");
      setPopupTitle("Недостаточно средств");
      setPopupDescription(
        `Для использования сума на торговом балансе должна быть более ${promotion === 'base' ? 10 : 100} USDT`
      );
      setIsOpenPopup(true);
      return false; // Indicate insufficient funds
    }
    return true; // Indicate sufficient funds
  };

  function launchBaseBot() {
    if (handleInsufficientFunds("base")) {
      setPromotion("base");
      setIsModalOpen(true);
    }
  }

  function launchProBot() {
    if (handleInsufficientFunds("pro")) {
      setPromotion("pro");
      setIsModalOpen(true);
    }
  }
  function launchComboBot() {
    if (handleInsufficientFunds('combo')) {
      setPromotion("combo");
      setIsModalOpen(true);
    }
  }


  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.target.keyCode === "ESC") {
      setIsModalOpen(false);
    }
  };
  const closePopup = () => {
    resetState();
  };
  const handleReplenishBotBalance = (tariff, amount, currency) => {
    handleSuccessfulLaunch(tariff, amount, currency);
  };
  return (
    <section className="binary-bots-section">
      <div className="binary-bots">
        <TradingOffer
          launchBot={launchBaseBot}
          offerName="Trading AI"
          sum={10}
          profit={7}
          customizable={true}
        />
        <TradingOffer
          launchBot={launchProBot}
          offerName="Arbitrage AI"
          sum={100}
          profit={10}
          customizable={true}
        />
        <TradingOffer
          launchBot={launchComboBot}
          offerName="Combo AI"
          sum={100}
          profit={'18-20'}
          customizable={true}
        />
        <ModalWindow onModalClose={closeModal} expanded={isOpenPopup}>
          <Popup
            type={popupType}
            title={t(popupTitle)}
            description={popupDescription}
            switchDone={closePopup}
          />
        </ModalWindow>
        <ModalWindow onModalClose={closeModal} expanded={isModalOpen}>
          <ReplenishBotBalance
            handleReplenishBotBalance={handleReplenishBotBalance}
            promotion={promotion}
          />
        </ModalWindow>
      </div>
    </section>
  );
};
