import { forwardRef } from "react";

const VideoBlock = forwardRef((props, ref) => {
  const english = localStorage.getItem("lang") === "en";
  return (
    <section ref={ref} className="main-page-section video-section">
      <div className="section-inner-container">
        <iframe
          width="1151"
          height="639"
          src={english ? 'https://www.youtube.com/embed/LQLYpW8EG3I' :"https://www.youtube.com/embed/ZEjRP2UEvSY"}
          title={english ? "CrypTouch AI - Promo 2023 ENG" : "CrypTouch AI - промо RU"}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
});
export { VideoBlock };


